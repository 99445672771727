import React from 'react';

function Audio({ data, innerRef }) {
  const { url, type, overlap, delay } = data.data;

  return (
    <div
      className="c-section__item item--audio"
      ref={innerRef}
      data-type={type}
      data-overlap={overlap}
      data-delay={delay}
    >
      <audio playsInline muted preload="auto">
        <source src={url} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

const AudioRef = React.forwardRef((data, ref) => (
  <Audio data={data} innerRef={ref} />
));

export { AudioRef };

import React from 'react';
import Link from './Link';
import {
  getBlockAlign,
  getBlockWidth
} from '../utils/blockAttributes';

function Text({ data, innerRef }) {
  const {
    type,
    text,
    related,
    top,
    left,
    options: { align, width, effect, offset }
  } = data.data;
  return (
    <div
      className={`c-grid__item ${getBlockWidth(
        width
      )} ${getBlockAlign(align)}`}
      ref={innerRef}
      data-effect={effect}
      data-type={type}
      data-offset={offset}
    >
      <div
        className="c-grid__item-text"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
      {related && <Link related={related} top={top} left={left} />}
    </div>
  );
}

const TextRef = React.forwardRef((data, ref) => (
  <Text data={data} innerRef={ref} effect={'fadeIn'} />
));

export { Text, TextRef };

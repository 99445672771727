import React, { Fragment, useEffect, useState } from 'react';
import { useChapters } from '../App';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Section from './Section';

gsap.registerPlugin(ScrollToPlugin);

function Chapter() {
  const searchParams = new URL(document.location).searchParams;
  const { data, status } = useChapters();
  const [chapter, setChapter] = useState();
  const [hasChapter, setHasChapter] = useState(false);

  useEffect(() => {
    if (status === 'success') {
      if (
        searchParams.get('chapter') === undefined ||
        searchParams.get('chapter') === '' ||
        searchParams.get('chapter') === null
      ) {
        setChapter([data[0]]);
      } else {
        setHasChapter(true);
        setChapter(
          data.filter(array => {
            return array.id == searchParams.get('chapter');
          })
        );
      }
    }
  }, [status]);

  const handleClickScroll = e => {
    const element = e.currentTarget.parentElement.nextSibling;
    if (element) {
      gsap.to(window, 2, { scrollTo: element });
    }
  };

  return (
    <>
      {data &&
        chapter?.map((chapter, i) => {
          return (
            <React.Fragment key={i}>
              {!hasChapter && (
                <div
                  className="c-chapter c-chapter--start"
                  id="chapter-start"
                  data-id={chapter.id}
                  key={i}
                >
                  <h1>{chapter.title}</h1>
                  <div
                    className="c-chapter__subtitle"
                    dangerouslySetInnerHTML={{
                      __html: chapter.subtitle
                    }}
                  />
                  <div className="c-chapter__path c-chapter__path--l"></div>
                  <div
                    className="c-chapter__play"
                    onClick={handleClickScroll}
                  >
                    <button
                      className="c-btn c-btn--m"
                      aria-label="Scroll to first elements in chapter"
                    >
                      <span className="icon icon--arrow"></span>
                    </button>
                  </div>
                  <div className="c-chapter__bg">
                    <img
                      src={chapter.background}
                      alt={chapter.title}
                    />
                  </div>
                </div>
              )}
              <div
                className="chapter-starter"
                id={hasChapter ? 'chapter-start' : ''}
              >
                {chapter.sections?.map((section, i) => {
                  return (
                    <Section
                      data={section.blocks}
                      options={section.options}
                      key={i}
                    />
                  );
                })}
              </div>
              {chapter?.next.id != 0 && (
                <div
                  className="c-chapter c-chapter--end"
                  data-id={chapter.next.id}
                >
                  <h1>{chapter.next.title}</h1>
                  <div
                    className="c-chapter__subtitle"
                    dangerouslySetInnerHTML={{
                      __html: chapter.next.subtitle
                    }}
                  />
                  <div className="c-chapter__path c-chapter__path--m"></div>
                  <div
                    className="c-chapter__play"
                    onClick={handleClickScroll}
                  >
                    <a
                      className="c-btn c-btn--m"
                      aria-label="Scroll to first elements in chapter"
                      href={`?chapter=${chapter.next.id}`}
                    >
                      <span className="icon icon--arrow"></span>
                    </a>
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
    </>
  );
}

export default Chapter;

import { useContext } from 'react';
import { ModalContext } from '../context/context';

function Link({ related, top, left }) {
  const { setIsModalOpen, setFetchID } = useContext(ModalContext);
  const onClickHandler = e => {
    setIsModalOpen(true);
    setFetchID(e.target.dataset.id);
  };
  const onHoverHandler = e => {
    setFetchID(e.target.dataset.id);
  };

  const style = {};
  if (top) style['--top'] = `${top}%`;
  if (left) style['--left'] = `${left}%`;

  return (
    <button
      className="c-btn c-btn--link"
      data-id={related}
      onClick={onClickHandler}
      onMouseOver={onHoverHandler}
      aria-label="Informationen"
      style={style}
    >
      <span className="icon icon--read" data-id={related}></span>
    </button>
  );
}

export default Link;

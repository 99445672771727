import React from 'react';
import Link from './Link';
import Zoom from 'react-medium-image-zoom';
import {
  getBlockAlign,
  getBlockWidth
} from '../utils/blockAttributes';
import { isWebpSupported } from 'react-image-webp/dist/utils';

function ImageEffect({ data, innerRef }) {
  const {
    url,
    urlwebp,
    urllayerone,
    urllayeronewebp,
    related,
    type,
    options: { offset, align, effect, width, gap, parallax }
  } = data.data;

  return (
    <div
      className={`c-grid__item ${getBlockWidth(
        width
      )} ${getBlockAlign(align)} ${
        gap === 0 ? 'has-gap' : 'has-nogap'
      }`}
      ref={innerRef}
      data-effect={effect}
      data-parallax={parallax}
      data-type={type}
      data-offset={offset}
      data-related={related}
    >
      <div className="image-wrap">
        {isWebpSupported() ? (
          <img
            src={urlwebp.src}
            alt="item"
            loading="lazy"
            width={urlwebp.width}
            height={urlwebp.height}
          />
        ) : (
          <img
            src={url.src}
            alt="item"
            loading="lazy"
            width={url.width}
            height={url.height}
          />
        )}
        {isWebpSupported() ? (
          <img
            src={urllayeronewebp.src}
            alt="item"
            loading="lazy"
            width={urllayeronewebp.width}
            height={urllayeronewebp.height}
          />
        ) : (
          <img
            src={urllayerone.src}
            alt="item"
            loading="lazy"
            width={urllayerone.width}
            height={urllayerone.height}
          />
        )}
      </div>
      {related && <Link related={related} />}
    </div>
  );
}

const ImageEffectRef = React.forwardRef((data, ref) => (
  <ImageEffect data={data} innerRef={ref} effect={'fadeIn'} />
));

export { ImageEffect, ImageEffectRef };

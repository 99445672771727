import Header from './components/Header';
import Controls from './components/Controls';
import Pagination from './components/Pagination';
import Intro from './components/Intro';
import Chapter from './components/Chapter';
import Modal from './components/Modal';

import { getData, getStart } from './utils/api.js';
import {
  QueryClient,
  QueryClientProvider,
  useQuery
} from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 10000000 * 20
    }
  }
});
export const useChapters = () =>
  useQuery({ queryKey: ['chapters'], queryFn: getData });
export const useStart = () =>
  useQuery({ queryKey: ['start'], queryFn: getStart });

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Header />
        <Pagination />
        <Controls />
        <Intro />
        <Chapter />
        <Modal />
      </QueryClientProvider>
    </div>
  );
}

export default App;

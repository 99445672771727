import axios from 'axios';

const getStart = async () => {
  const data = await axios({
    method: 'post',
    url: `${
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_URL
        : process.env.REACT_APP_URLPROD
    }1/`
    // auth: {
    //   username: `${process.env.REACT_APP_USER}`,
    //   password: `${process.env.REACT_APP_PW}`
    // }
  });
  return data.data;
};

const getData = async () => {
  const chapters = [];
  const data = await axios({
    method: 'post',
    url: `${
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_URL
        : process.env.REACT_APP_URLPROD
    }1/`
    // auth: {
    //   username: `${process.env.REACT_APP_USER}`,
    //   password: `${process.env.REACT_APP_PW}`
    // }
  });
  for (const chapter of data.data.chapters) {
    const result = await axios({
      method: 'post',
      url: `${
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_URL
          : process.env.REACT_APP_URLPROD
      }${chapter.chapterItem}`
      // auth: {
      //   username: `${process.env.REACT_APP_USER}`,
      //   password: `${process.env.REACT_APP_PW}`
      // }
    });
    chapters.push(result.data);
  }
  return chapters;
};

const getModalData = async ID => {
  const data = await axios({
    method: 'post',
    url: `${
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_URL
        : process.env.REACT_APP_URLPROD
    }${ID}/`
    // auth: {
    //   username: `${process.env.REACT_APP_USER}`,
    //   password: `${process.env.REACT_APP_PW}`
    // }
  });
  return data.data;
};
export { getData, getStart, getModalData };

import React, {
  useEffect,
  useState,
  useContext,
  useCallback
} from 'react';
import Loading from './Loading';
import image from './../static/img/bg.png';
import { useStart } from '../App';
import { useChapters } from '../App';
import { gsap } from 'gsap';
import { ModalContext } from '../context/context';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

const Intro = React.memo(() => {
  const { isOn, setIsOn } = useContext(ModalContext);
  const { data: chapterData, status: chapterStatus } = useChapters();
  const [start, setStart] = useState({
    title: null,
    subtitle: null,
    bg: null
  });
  const { data, status } = useStart();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const params = new URLSearchParams(window.location.search);
    const chapter = params.get('chapter');

    if (status === 'success') {
      if (!chapter) {
        setStart({
          title: data?.title,
          subtitle: data?.subtitle,
          bg: data?.background
        });
        data?.color &&
          document.documentElement.style.setProperty(
            '--highlight',
            data.color
          );
      } else {
        const selectedChapter = chapterData?.find(
          array => array.id == chapter
        );
        if (selectedChapter) {
          setStart({
            title: selectedChapter.title,
            subtitle: selectedChapter.subtitle,
            bg: selectedChapter.background
          });
          selectedChapter.color &&
            document.documentElement.style.setProperty(
              '--highlight',
              selectedChapter.color
            );
        }
      }
    }

    if (isOn) {
      document.body.classList.add('is-on');
    }
  }, [isOn, data, status, chapterData]);

  const handleClickScroll = useCallback(() => {
    const element = document.getElementById('chapter-start');
    if (element) {
      const audios = document.querySelectorAll('audio');
      audios.forEach(a => (a.muted = 0));
      gsap.to(window, 4, { scrollTo: element });
      setIsOn(true);
    }
  }, [setIsOn]);

  return (
    <div className="c-chapter c-chapter--intro c-chapter--path">
      <h1>{start.title}</h1>
      <div
        className="c-chapter__subtitle"
        dangerouslySetInnerHTML={{ __html: start.subtitle }}
      />
      <div className="c-chapter__play">
        <button
          className={`c-btn c-btn--trigger c-btn--l ${
            status === 'success' ? 'is-visible' : 'is-hidden'
          }`}
          onClick={handleClickScroll}
          aria-label="Click to play"
        >
          <span
            className="icon icon--play"
            aria-label="play & go"
            role="img"
          ></span>
        </button>
        <span
          className={`icon-text ${
            status === 'success' ? 'is-visible' : 'is-hidden'
          }`}
        >
          Klicken zum abspielen
        </span>
        {status === 'loading' && <Loading />}
        {status === 'error' && (
          <div className="c-error">
            Leider ist ein Fehler aufgetreten
          </div>
        )}
      </div>
      <div className="c-chapter__bg">
        <img
          src={start.bg === 'null' ? image : start.bg}
          alt={start.title}
          loading="lazy"
        />
      </div>
    </div>
  );
});

export default Intro;

import { useContext } from 'react';
import { useChapters } from '../App';
import { gsap } from 'gsap';
import { ModalContext } from '../context/context';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

function Pagination() {
  const searchParams = new URL(document.location).searchParams;
  const { isOn } = useContext(ModalContext);
  const { data } = useChapters();

  return (
    <>
      {isOn && (
        <div className="c-pagination">
          <ul>
            {data &&
              data?.map((pagi, index) => {
                return (
                  <li key={index}>
                    <a
                      href={`?chapter=${pagi.id}`}
                      data-target={pagi.id}
                      className={`c-pagination__item ${
                        pagi.id == searchParams.get('chapter') && 'is-current'
                      } ${
                        searchParams.get('chapter') === null &&
                        index === 0 &&
                        'is-current'
                      }`}
                    >
                      <span>{index + 1}</span>
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
}

export default Pagination;

import { createContext, useState } from 'react';

export const ModalContext = createContext({
  isModalOpen: null,
  setIsModalOpen: () => null,
  hasFetchID: null,
  setFetchID: () => null,
  isOn: null,
  setIsOn: () => null,
  isAutoScrolling: null,
  setIsAutoScrolling: () => null,
  isMuted: null,
  setIsMuted: () => null,
});

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasFetchID, setFetchID] = useState(null);
  const [isOn, setIsOn] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const value = {
    isModalOpen,
    setIsModalOpen,
    hasFetchID,
    setFetchID,
    isOn,
    setIsOn,
    isAutoScrolling,
    setIsAutoScrolling,
    isMuted,
    setIsMuted,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

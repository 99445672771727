const getBlockWidth = width => {
  switch (width) {
    case 'Cover':
      return 'c-grid__item--cover';
    case '100%':
      return 'c-grid__item--full';
    case '75%':
      return 'c-grid__item--third';
    case '50%':
      return 'c-grid__item--half';
    case '33%':
      return 'c-grid__item--quarter';
    default:
      return 'c-grid__item--autoWidth';
  }
};

const getBlockAlign = align => {
  switch (align) {
    case 'Right':
      return 'c-grid__item--right';
    case 'Left':
      return 'c-grid__item--left';
    case 'Center':
      return 'c-grid__item--center';
    default:
      return 'c-grid__item--autoAlign';
  }
};

export { getBlockAlign, getBlockWidth };

import React from 'react';
import Link from './Link';
import { getBlockAlign, getBlockWidth } from '../utils/blockAttributes';

function ImageRotate({ data, innerRef }) {
  const {
    url,
    related,
    type,
    options: { offset, align, effect, width, gap },
  } = data.data;
  return (
    <div className="c-grid__rotate">
      <div
        className={`c-grid__item ${getBlockWidth(width)} ${getBlockAlign(
          align
        )} ${gap === 0 ? 'has-gap' : 'has-nogap'}`}
        ref={innerRef}
        data-effect={effect}
        data-type={type}
        data-offset={offset}
        data-related={related}
      >
        <img
          src={url.src}
          alt="item"
          loading="lazy"
          width={url.width}
          height={url.height}
        />
        {related && <Link related={related} />}
      </div>
    </div>
  );
}

const ImageRotateRef = React.forwardRef((data, ref) => (
  <ImageRotate data={data} innerRef={ref} effect={'fadeIn'} />
));

export { ImageRotate, ImageRotateRef };

import { useState, useContext, useEffect, useRef } from 'react';
import { ModalContext } from '../context/context';
import { getModalData } from '../utils/api';

function Modal() {
  const { isModalOpen, setIsModalOpen, hasFetchID } =
    useContext(ModalContext);
  const onClickHandler = () => setIsModalOpen(false);
  const modalText = useRef();

  const [modal, setModal] = useState({});

  useEffect(() => {
    if (hasFetchID != null) {
      const modalData = async () => {
        const data = await getModalData(hasFetchID);
        setModal(data);
      };
      modalData();
      modalText.current.scrollTop = 0;
    } else {
    }
  }, [isModalOpen, hasFetchID]);

  return (
    <div
      className={`c-modal ${isModalOpen && 'is-active'}`}
      id="modal"
    >
      <button
        className="c-btn-close"
        aria-controls="modal"
        onClick={onClickHandler}
        aria-label="Close Information Modal"
      ></button>
      <h3 className="c-modal__title">{modal.title}</h3>
      <span className="c-modal__author">Von {modal.author}</span>
      <div
        className="c-modal__content o-wysiwyg"
        dangerouslySetInnerHTML={{ __html: modal.content }}
        ref={modalText}
      />
    </div>
  );
}

export default Modal;

import { useState, useEffect, useContext } from 'react';
import { gsap, Linear } from 'gsap';
import { ModalContext } from '../context/context';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
gsap.config({ autoKillThreshold: 1 });

function Controls() {
  const { isOn, isMuted, setIsMuted } = useContext(ModalContext);
  const [isScrolling, setIsScrolling] = useState(false);

  const onClickMuted = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    const audios = document.querySelectorAll('audio');
    isMuted
      ? audios.forEach(a => (a.muted = 1))
      : audios.forEach(a => (a.muted = 0));
  }, [isMuted]);

  const onClickScroll = () => {
    if (!isScrolling) {
      gsap.to(window, 80, {
        scrollTo: {
          y: 'max',
          autoKill: false,
          ease: Linear.easeNone
        },
        ease: Linear.easeNone
      });
      setIsScrolling(!isScrolling);
    } else {
      setIsScrolling(!isScrolling);
      gsap.killTweensOf(window);
    }
  };

  return (
    <div className="c-controls">
      <ul>
        <li>
          <button
            className={`c-btn c-btn--sound ${
              isMuted && 'is-active'
            }`}
            onClick={onClickMuted}
            aria-label="Play/Mute Sound"
          >
            <span
              className={`icon icon--sound ${
                isMuted && 'icon--sound-off'
              }`}
            ></span>
          </button>
        </li>
        {isOn && (
          <li>
            <button
              className={`c-btn c-btn--scroll c-btn--s ${
                isScrolling && 'is-active'
              }`}
              onClick={onClickScroll}
              aria-label="Autoplay Scroll"
            >
              <span className="icon icon--autoplay"></span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Controls;

import React from 'react';
import Link from './Link';
import {
  getBlockAlign,
  getBlockWidth
} from '../utils/blockAttributes';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import Zoom from 'react-medium-image-zoom';

function Image({ data, innerRef }) {
  const {
    url,
    urlwebp,
    related,
    type,
    top,
    left,
    options: { offset, align, effect, width, gap, parallax }
  } = data.data;

  return (
    <div
      className={`c-grid__item ${getBlockWidth(
        width
      )} ${getBlockAlign(align)} ${
        gap === 0 ? 'has-gap' : 'has-nogap'
      }`}
      ref={innerRef}
      data-effect={effect}
      data-parallax={parallax}
      data-type={type}
      data-offset={offset}
      data-related={related}
    >
      {isWebpSupported() ? (
        <img
          src={urlwebp.src}
          alt="item"
          loading="lazy"
          width={url.width}
          height={url.height}
        />
      ) : (
        <img
          src={url.src}
          alt="item"
          loading="lazy"
          width={url.width}
          height={url.height}
        />
      )}
      {related && <Link related={related} top={top} left={left} />}
    </div>
  );
}

const ImageRef = React.forwardRef((data, ref) => (
  <Image data={data} innerRef={ref} />
));

export { Image, ImageRef };
